html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.version {
  position: absolute;
  font-size: 15px;
  color: gray;
  bottom: 26px;
  text-align: center;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  background: #fafafa;
}

.meeting {
  display: flex;
  background-color:#000;
  flex: 1;
}

.current-view {
  display: flex;
  flex: 1;
  /* position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #666;
  background-size: 280px;
  background-image: url("./assets/logo-open-live-gray.png"); */
}

.stream-player {
  display: flex;
  flex: 1;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #000;
  background-size: 280px;
  background-image: url("./assets/logo-open-live-gray.png");
  /* width:50%;
  aspect-ratio: 16 / 9; */
}

.messages-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 1);
  flex-direction: column;
  box-sizing: border-box;
  width: 350px;
  margin-left: 10px;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  z-index: 4;
}
.messages-area {
  background: rgba(250, 250, 250, 1);
  height: "70vh";
  overflow-y: "auto";
}

.diamond-avatar {
  position: relative;
  display: block;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  overflow: hidden;

  background-color: #fff de;
  background-image: url("./assets/icon-diamond.gif");
  background-size: 30px;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 28px;
}

@keyframes shiny {
  0% { left: -20%; }
  10% { left: 120%; }
  100% { left: 120%; }
}

.xym-avatar::after {
  content: '';
  position: absolute;
  top: -10%;
  left: -20%;
  width: 40px;
  height: 100%;
  transform: scale(2) rotate(20deg);
  background-image: linear-gradient(100deg, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, .5) 100%, rgba(255, 255, 255, 0) 0%);
  
  /* アニメーション */
  animation-name: shiny;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.xym-avatar {
  position: relative;
  display: block;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  overflow: hidden;

  background-color: #fff de;
  background-image: url("./assets/icon-xym.png");
  background-size: 35px;
  display: block;
  width: 35px;
  height: 35px;
  border-radius: 28px;
}

.xym-avatar:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.xym-avatar-mobile::after {
  content: '';
  position: absolute;
  top: -10%;
  left: -20%;
  width: 20px;
  height: 100%;
  transform: scale(2) rotate(20deg);
  background-image: linear-gradient(100deg, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, .5) 100%, rgba(255, 255, 255, 0) 0%);
  
  /* アニメーション */
  animation-name: shiny;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.xym-avatar-mobile {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  overflow: hidden;

  background-color: #fff de;
  background-image: url("./assets/icon-xym.png");
  background-size: 20px;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 28px;
}

.xym-avatar-mobile:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.nav {
  position: absolute;
  display: flex;
  width: 100%;
  top: 0;
  /* left: 0; */
  justify-content: right;
  z-index: 3;
}

.quit {
  display: block;
  margin-top: 10px;
  margin-right: 10px;
  background-image: url("./assets/icon-exit-hover.png");
  background-size: 32px;
  width: 32px;
  height: 32px;
  border-radius: 30px;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.quit:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.top {
  display: block;
  margin-top: 10px;
  margin-right: 10px;
  background-image: url("./assets/icon-top-hover.png");
  background-size: 32px;
  width: 32px;
  height: 32px;
  border-radius: 30px;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.top:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.reload {
  display: block;
  margin-top: 10px;
  margin-right: 10px;
  background-image: url("./assets/icon-reload-hover.png");
  background-size: 32px;
  width: 32px;
  height: 32px;
  border-radius: 30px;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.reload:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.stream-uid {
  padding: 2px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  left: 5px;
  bottom: 7px;
  z-index: 2;
  position: absolute;
  border-radius: 28px;
  max-width: 250px;
  max-height: 20px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  font-size: 12px;
  font-family: HelveticaNeue;
  color: rgba(255, 255, 255, 1);
  line-height: 14px;
}

.stream-uid-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  left: 0px;
  bottom: 0px;
  z-index: 2;
  position: absolute;
  border-radius: 28px;
  line-height: 14px;
}
.stream-time {
  padding: 2px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  right: 5px;
  bottom: 7px;
  z-index: 2;
  position: absolute;
  border-radius: 28px;
  max-width: 100px;
  max-height: 20px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  font-size: 12px;
  font-family: HelveticaNeue;
  color: rgba(255, 255, 255, 1);
  line-height: 14px;
}

.stream-stop {
  padding: 2px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  right: 5px;
  bottom: 7px;
  z-index: 2;
  position: absolute;
  border-radius: 28px;
  max-width: 100px;
  max-height: 20px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  font-size: 12px;
  font-family: HelveticaNeue;
  color: rgba(255, 255, 255, 1);
  line-height: 14px;
  opacity: 0.5;

}
.stream-stop:hover {
  opacity: 1;
}

.stream-icon {
  padding: 2px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  right: 5px;
  z-index: 4;
  position: absolute;
  border-radius: 28px;
  max-width: 100px;
  max-height: 20px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  font-size: 12px;
  font-family: HelveticaNeue;
  color: rgba(255, 255, 255, 1);
  line-height: 14px;
  opacity: 0.5;
}
.stream-icon:hover {
  opacity: 1;
}

.stream-hide {
  padding: 2px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  left: 5px;
  z-index: 4;
  position: absolute;
  border-radius: 28px;
  max-width: 100px;
  max-height: 20px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  font-size: 12px;
  font-family: HelveticaNeue;
  color: rgba(255, 255, 255, 1);
  line-height: 14px;
  opacity: 0.5;
}
.stream-hide:hover {
  opacity: 1;
}


.stream-stop-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0px;
  right: 0px;
  z-index: 4;
  position: absolute;
  opacity: 0.5;
}
.stream-stop-mobile:hover {
  opacity: 1;
}

.stream-icon-mobile {
  padding: 1px 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: 0px;
  z-index: 4;
  position: absolute;
  opacity: 0.5;
}
.stream-icon-mobile:hover {
  opacity: 1;
}

.stream-hide-mobile {
  padding: 1px 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  z-index: 4;
  position: absolute;
  opacity: 0.5;
}
.stream-hide-mobile:hover {
  opacity: 1;
}

.stream-image {
  padding: 2px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  right: 5px;
  z-index: 4;
  position: absolute;
  border-radius: 28px;
  max-width: 100px;
  max-height: 20px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  font-size: 12px;
  font-family: HelveticaNeue;
  color: rgba(255, 255, 255, 1);
  line-height: 14px;
  opacity: 0.5;
}
.stream-image:hover {
  opacity: 1;
}

.disable-video {
  background-image: url("./assets/icon-camera-disable.png");
}

.mute-video {
  background-image: url("./assets/icon-camera-off.png");
}

.mute-video:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.unmute-video {
  background-image: url("./assets/icon-camera.png");
}

.unmute-video:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.disable-audio {
  background-image: url("./assets/icon-microphone-disable.png");
}

.mute-audio {
  background-image: url("./assets/icon-microphone-off.png");
}

.mute-audio:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.unmute-audio {
  background-image: url("./assets/icon-microphone.png");
}

.unmute-audio:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.virtual {
  background-image: url("./assets/icon-virtual.png");
}

.virtual:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.off-virtual {
  background-image: url("./assets/icon-virtual-off.png");
}

.off-virtual:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.disable-virtual {
  background-image: url("./assets/icon-virtual-disable.png");
}


.bgm {
  background-image: url("./assets/icon-bgm.png");
}

.bgm:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.off-bgm {
  background-image: url("./assets/icon-bgm-off.png");
}

.off-bgm:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.disable-bgm {
  background-image: url("./assets/icon-bgm-disable.png");
}


.resolution-low {
  background-image: url("./assets/icon-resolution-low.png");
}

.resolution-low:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.resolution-low-disable {
  background-image: url("./assets/icon-resolution-low-disable.png");
}

.resolution-high {
  background-image: url("./assets/icon-resolution-high.png");
}

.resolution-high:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.resolution-high-disable {
  background-image: url("./assets/icon-resolution-high-disable.png");
}


.rotate-camera {
  background-image: url("./assets/icon-rotate.png");
}

.rotate-camera:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.off-rotate-camera {
  background-image: url("./assets/icon-rotate-off.png");
}

.off-rotate-camera:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.disable-rotate-camera {
  background-image: url("./assets/icon-rotate-disable.png");
}

.disable-guest {
  background-image: url("./assets/icon-guest-disable.png");
}

.leave-guest {
  background-image: url("./assets/icon-guest-off.png");
}

.leave-guest:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.join-guest {
  background-image: url("./assets/icon-guest.png");
}

.join-guest:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.disable-guest {
  background-image: url("./assets/icon-guest-disable.png");
}

.disconnect {
  background-image: url("./assets/icon-disconnect.png");
}

.disconnect:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.off-screen-share {
  background-image: url("./assets/icon-share-off.png");
}

.off-screen-share:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.disable-screen-share {
  background-image: url("./assets/icon-share-disable.png");
}

.disable-screen-share:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.start-screen-share {
  background-image: url("./assets/icon-share.png");
}

.start-screen-share:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.back-btn {
  background-size: 32px;
  background-image: url("./assets/icon-back.png");
  background-repeat: no-repeat;
  top: 1rem;
  height: 32px;
  position: absolute;
  width: 32px;
  left: 1rem;
  cursor: pointer;
  z-index: 2;
}

.back-btn:hover {
  background-image: url("./assets/icon-back-hover.png");
}

.role-container {
  display: flex;
  width: 300px;
  align-items: center;
  justify-content: space-around;
}

.role-item {
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.inactive:hover .role-name {
  color: #44a2fc;
}

.inactive:hover .icon-audience {
  background-image: url("./assets/icon-audience.png");
}

.inactive:hover .icon-host {
  background-image: url("./assets/icon-host.png");
}

.inactive .role-name {
  padding-left: 5px;
  font-size: 14px;
  color: #eaeaea;
}

.active .role-name {
  padding-left: 5px;
  font-size: 14px;
  color: #44a2fc;
}

.custom-radio {
  position: relative;
  width: 14px;
  height: 14px;

  /* When the radio button is checked, add a blue background */
}

.custom-radio:hover [type="radio"] ~ .checkmark {
  background-color: #ccc;
}

.custom-radio [type="radio"]:checked ~ .checkmark {
  background-color: #2196f3;
}

.custom-radio [type="radio"],
.custom-radio .checkmark {
  width: 14px;
  height: 14px;
}

.custom-radio [type="radio"] {
  margin: 0;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 4;
}

.custom-radio [type="radio"]:checked ~ .checkmark::after {
  display: block;
}

.custom-radio .checkmark::after {
  content: "";
  position: absolute;
  display: none;
  top: 4px;
  left: 4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: white;
}

.custom-radio .checkmark {
  top: 0;
  left: 0;
  background-color: #eee;
  border-radius: 50%;
}

.radio-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 75px;
  max-width: 75px;
  position: absolute;
  bottom: top;
  top: 132px;
  left: 19px;
}

.radio-row.host {
  left: 22px;
}

.radio-row.audience {
  left: 13px;
}

.error-icon {
  padding-right: 10px;
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-image: url("./assets/icon-error.png");
}

.cover-image {
  margin-top: 4rem;
  width: 180px;
  height: 180px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("./assets/logo-open-live.png");
}

.active .icon-audience {
  width: 100px;
  height: 118px;
  background-size: 100px 118px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./assets/icon-audience.png");
}

.inactive .icon-audience {
  width: 100px;
  height: 118px;
  background-size: 100px 118px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./assets/icon-audience-inactived.png");
}

.active .icon-host {
  width: 100px;
  height: 118px;
  background-size: 100px 118px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./assets/icon-host.png");
}

.inactive .icon-host {
  width: 100px;
  height: 118px;
  background-size: 100px 118px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./assets/icon-host-inactived.png");
}

.stream-container {
  left: 380px;
  top: 10px;
  height: 160px;
  width: 240px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 3;
}

.stream-container > .stream-player {
  border: 2px solid #fff;
  max-height: 160px;
  background: rgba(216, 216, 216, 1);
  max-width: 240px;
  margin: 4px 0;
  position: relative;
  border-radius: 2px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #666;
  background-size: 140px;
  background-image: url("./assets/logo-open-live-gray.png");
}

.stream-container-mobile {
  top: 160px;
  height: 80px;
  width: 120px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 3;
}

.stream-container-mobile > .stream-player {
  border: 2px solid #fff;
  height: 80px;
  background: rgba(216, 216, 216, 1);
  width: 120px;
  margin: 4px 0;
  position: absolute;
  border-radius: 2px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #666;
  background-size: 70px;
  background-image: url("./assets/logo-open-live-gray.png");
}

.local-player {
  height: 160px;
  background: rgba(216, 216, 216, 1);
  width: 240px;
  margin: 4px 0;
  border-radius: 2px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #666;
  background-size: 140px;
  background-image: url("./assets/logo-open-live-gray.png");
  transform: scale(-1,1);
}

.info-text {
  animation: flash 1s linear infinite;
}

.live-text {
  animation: flash 2s linear infinite;
}

@keyframes flash {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.gold {
  background: linear-gradient(
    45deg,
    #b67b03 0%,
    #daaf08 45%,
    #fee9a0 70%,
    #daaf08 85%,
    #b67b03 90% 100%
  );
}

.silver {
  background: linear-gradient(
    45deg,
    #757575 0%,
    #9e9e9e 45%,
    #e8e8e8 70%,
    #9e9e9e 85%,
    #757575 90% 100%
  );
}

.black {
  background: linear-gradient(
    45deg,
    #212121 0%,
    #424242 45%,
    #fafafa 70%,
    #424242 85%,
    #212121 90% 100%
  );
}

.symbol {
  background: linear-gradient(
    45deg,
    #800080 0%,
    #ff00ff 45%,
    #ffe5ff 70%,
    #ff00ff 85%,
    #800080 90% 100%
  );
}

.ribbon-wrapper {
  display: block;
  position: relative;
  /* margin: 15px auto;
  padding: 10px 0;
  width: 300px;
  height: 150px; */
  /* background: #f1f1f1; */
  /* box-shadow: 0 3px 5px rgba(0, 0, 0, 0.14);
  box-sizing: border-box; */
}

.ribbon-content {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 89px;
  height: 91px;
  overflow: hidden;
}

.ribbon {
  display: inline-block;
  position: absolute;
  padding: 7px 0;
  left: -23px;
  top: 22px;
  width: 160px;
  text-align: center;
  font-size: 18px;
  line-height: 16px;
  background: linear-gradient(to right, #4298fa 0%, #8fcdff 100%);
  color: #fff;
  text-shadow: 0 2px 2px #56a1f3;
  letter-spacing: 0.05em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.ribbon:before,
.ribbon18:after {
  position: absolute;
  content: "";
  border-top: 4px solid #3672b6;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  bottom: -4px;
}

.ribbon:before {
  left: 14px;
}

.ribbon:after {
  right: 18px;
}

.ribbon-content-chat {
  position: absolute;
  top: 0;
  right: 0;
  width: 49px;
  height: 49px;
  overflow: hidden;
}

.ribbon-chat {
  display: inline-block;
  position: absolute;
  padding: 5px 0;
  left: -16px;
  top: 12px;
  width: 100px;
  text-align: center;
  font-size: 1px;
  line-height: 1px;
  background: rgb(66, 152, 250);
  color: #fff;
  letter-spacing: 0.05em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  box-shadow: 0 0 0 2px rgb(66, 152, 250);
  border-top: dashed 1px rgba(255, 255, 255, 0.65);
  border-bottom: dashed 1px rgba(255, 255, 255, 0.65);
}

.progress-bar {
  transition: none;
}

.titleBox {
  background-image: repeating-linear-gradient(-60deg, #4f97dc, #4f97dc 2px, transparent 2px, transparent 7px, #4f97dc 7px), repeating-linear-gradient(30deg, #4f97dc, #4f97dc 2px, transparent 2px, transparent 7px, #4f97dc 7px), repeating-linear-gradient(120deg, #4f97dc, #4f97dc 2px, transparent 2px, transparent 7px, #4f97dc 7px), repeating-linear-gradient(210deg, #4f97dc, #4f97dc 2px, transparent 2px, transparent 7px, #4f97dc 7px);
  background-size: 7px calc(100% + 14px), calc(100% + 14px) 7px, 7px calc(100% + 14px) , calc(100% + 14px) 7px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  animation: borderAnimation 0.4s infinite linear;
  }
  
  @keyframes borderAnimation {
  from { background-position: 0 0, -14px 0, 100% -14px, 0 100%; }
  to { background-position: 0 -14px, 0 0, 100% 0, -14px 100%; }
  }
  